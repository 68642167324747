<template>
  <div class="userRolePage">
    <div class="topDiv">
      <div class="topDivfl flDiv pagePadding">
        <div class="pageTitle">角色列表</div>
        <Table @change-page="changePage1" totalText="条记录" :total="total" :current="pages.page" :pages="pages" ref="table1" :loading="isLoad" :isLoad="isLoad" :productList="listColumns" :productData="listData" stripe border></Table>
      </div>
      <div class="topDivfl flDiv pagePadding">
        <div class="pageTitle">用户列表</div>
        <Table @change-page="changePage2" @on-select="onSelect" @on-select-cancel="onSelectionCancel" @on-select-all="onSelectAll" :loading="isLoad2" :isLoad="isLoad2" @on-select-all-cancel="onSelectionAllCancel" totalText="条记录" :pages="pages2" :total="total2" :current="pages.page" ref="table2" :productList="listColumns2" :productData="listData2" stripe border></Table>
      </div>
    </div>
    <div class="btmDiv">
      <span class="pageBtn finger btnSure" @click="saveUserofRole" v-if="modifyAble">保存</span>
      <!-- <span class="pageBtn finger btnReset marginLeft20"
            @click="cancleSelection">取消</span> -->
    </div>
  </div>
</template>

<script>
import Table from '@/components/table'
import salesReturnEditVue from '../salesManage/salesReturnEdit.vue'
export default {
  name: 'userRole',
  components: {
    Table,
  },
  watch: {
    lastPostList() {
      console.log(this.lastPostList)
    },
  },
  data() {
    return {
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限

      // 用来暂存 勾选的项目
      storageList: [],
      lastPostList: [], // 最终提交的数据
      isLoad: false,
      isLoad2: false,
      // 角色列表数据
      listData: [],
      // 用户列表数据
      listData2: [],
      // 角色列表标题
      listColumns: [
        {
          title: ' ',
          align: 'center',
          maxWidth: 75,
          // 对应列表内容的字段
          key: 'checkBox',
          // iview中的render函数
          render: (h, params) => {
            return h('div', [
              // 生成一个Checkbox 复选框
              h('Checkbox', {
                props: {
                  value: params.row.checkBox,
                  disabled: params.row.disabled,
                },
                on: {
                  // click: () => {
                  // },
                  'on-change': e => {
                    if (e) {
                      // 禁止用其他的角色列表
                      this.listData[params.index].checkBox = e // 再将勾选的对象的checkBox设置为true
                      this.listData.forEach((item, index) => {
                        if (item.checkBox != true) {
                          this.$set(item, 'disabled', true)
                        }
                      })
                      // console.log(this.listData)
                      // 用来存储 点击的角色id
                      this.postUserid = this.listData[params.index].id
                      this.getUserListByRoleId()
                    } else {
                      this.postUserid = null
                      this.cancleSelection()
                    }
                  },
                },
              }),
            ])
          },
        },
        {
          title: '序号',
          maxWidth: 75,
          type: 'index',
          align: 'center',
        },
        {
          title: '角色名称',
          key: 'role_name',
          align: 'center',
        },
      ],
      // 用户列表标题
      listColumns2: [
        {
          type: 'selection',
          maxWidth: 75,
          align: 'center',
        },
        {
          title: '序号',
          maxWidth: 75,
          type: 'index',
          align: 'center',
        },
        {
          title: '登录账号',
          key: 'login_name',
          align: 'center',
        },
        {
          title: '用户名称',
          key: 'user_name',
          align: 'center',
        },
      ],
      user_id: '', // 点击的用户
      selectList: [], // 存储获取过来的角色下的用户
      postUserid: -999, // 最好提交的时候的用户id
      total: 100,
      total2: 100,
      pages: {
        page: 1,
        rows: 10,
      },
      pages2: {
        page: 1,
        rows: 10,
      },
    }
  },
  methods: {
    // 全选触发事件
    onSelectAll(data) {
      this.$http.get(this.$api.permissionUser, { page: 1, rows: 10000 }, true).then(res => {
        this.storageList = res.data
        console.log(this.storageList)
        this.lastPostList = []
        this.storageList.forEach((item, index) => {
          this.lastPostList.push(item.user_id)
        })
      })
    },
    // 取消全选触发事件
    onSelectionAllCancel(data) {
      this.storageList = []
      this.lastPostList = []
    },
    // 取消勾单选触发事件
    onSelectionCancel(data, row) {
      // 0表触发项的索引
      let foo = 0
      // 遍历 暂存勾选项 获取触发项的索引
      this.storageList.forEach((item, index) => {
        if (item.user_id == row.user_id) {
          foo = index
        }
      })
      // 从 暂存勾选项 中删除该触发项
      this.storageList.splice(foo, 1)
    },
    // 勾单选触发事件
    onSelect(data, row) {
      // 本来让push
      let canPush = true
      // 如果在其中
      this.storageList.forEach((item, index) => {
        if (item.user_id == row.user_id) {
          canPush = false
          return null
        }
      })
      if (canPush) {
        this.storageList.push(row)
        this.lastPostList.push(row.user_id)
      }
    },
    cancleSelection() {
      this.storageList = []
      this.lastPostList = []
      // 清空用户列表数据
      this.listData2.forEach((item, index) => {
        // 将用户列表属性设置为false 全部取消选择
        this.$set(item, '_checked', false)
      })
      // 重置角色列表
      this.listData.forEach((item, index) => {
        this.$set(item, 'disabled', false)
      })
      this.listData.forEach(items => {
        // 先取消所有对象的勾选，checkBox设置为false
        this.$set(items, 'checkBox', false)
      })
    },
    // 获取角色列表
    getRoleList() {
      let data = {
        page: this.pages.page,
        rows: this.pages.rows,
      }
      this.isLoad = true
      this.$http.get(this.$api.permissionRoleList, data, true).then(res => {
        this.isLoad = false
        // console.log(res)
        this.listData = res.data
        // this.listData.forEach(items => {
        //   // 先取消所有对象的勾选，checkBox设置为false
        //   this.$set(items, 'checkBox', false)
        // })
        //  this.listData.forEach(items => {
        //   // 先取消所有对象的勾选，checkBox设置为false
        //   this.$set(items, 'disabled', false)
        // })
        this.total = res.total
      })
    },
    // 发送请求获取用户列表数据
    getUserList() {
      let data = {
        page: this.pages2.page,
        rows: this.pages2.rows,
      }
      this.isLoad2 = true
      this.$http.get(this.$api.permissionUser, data, true).then(res => {
        this.isLoad2 = false
        // console.log(res)
        // console.log(this.storageList)
        this.listData2 = res.data
        this.total2 = res.total
        // 遍历 用户列表数据  跟已经勾选的数据对比 如果存在已经勾选的就钩上
        if (this.postUserid != null) {
          this.listData2.forEach((item, index) => {
            this.storageList.forEach((item2, index2) => {
              if (item2.user_id == item.user_id) {
                item._checked = true
              }
            })
          })
        }
        if (this.postUserid != null) {
          this.listData2.forEach((item, index) => {
            // 循环遍历对应id下的用户列表
            for (let i = 0; i < this.selectList.length; i++) {
              if (item.user_id == this.selectList[i]) {
                this.$set(item, '_checked', true)
              }
            }
          })
        }
      })
    },
    // 通过角色id获取角色下的用户
    getUserListByRoleId() {
      let data = {
        role_id: this.postUserid,
      }
      if (this.postUserid != null) {
        // 调用api获取对应id的用户列表数组
        this.$http.get(this.$api.permissionUserRole, data, false).then(res => {
          // selectList是 点击角色列表后获取的当前角色 的用户id
          this.selectList = res.data
          this.lastPostList = res.data
          // console.log(this.selectList)
          // console.log(this.listData2)
          // 循环遍历用户列表
          this.listData2.forEach((item, index) => {
            // 循环遍历对应id下的用户列表
            for (let i = 0; i < this.selectList.length; i++) {
              if (item.user_id == this.selectList[i]) {
                this.$set(item, '_checked', true)
              }
            }
          })
        })
      }
    },
    // 设置角色下的用户
    saveUserofRole() {
      if (!this.postUserid) {
        this.$Message.warning('请填选择角色')
        return
      }
      if (this.lastPostList.length == 0) {
        this.$Message.warning('请填用户')
        return
      }
      let data = {
        role_id: this.postUserid,
        user_list: this.lastPostList,
      }
      console.log(data)
      this.$http.post(this.$api.permissionUserRole, data, true).then(res => {
        this.$Message.success('保存成功')
      })
    },
    //
    changePage1(e) {
      this.pages.page = e
      this.selectList = []
      this.lastPostList = []
      this.postUserid = null
      this.getRoleList()
      this.cancleSelection()
    },
    //
    changePage2(e) {
      this.pages2.page = e
      this.getUserList()
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = rightStr.modify
      this.searchAble = rightStr.search
    },
  },
  created() {
    this.getUserList()
    this.getRoleList()
    this.getRightVisibal()
  },
}
</script>

<style scoped lang="less">
.userRolePage {
  display: flex;
  flex-direction: column;
  .topDiv {
    flex: 1;
    display: flex;
    .topDivfl {
      flex: 1;
      .pageTitle {
        // color: pageTitle;
        height: 25px;
        line-height: 25px;
        margin-bottom: 18px;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .flDiv {
      border-right: 1px solid #e8eaec;
    }
  }
  .btmDiv {
    padding-right: 21px;
    text-align: right;
    height: 75px;
    line-height: 75px;
    border-top: 2px solid #e8eaec;
  }
}
</style>
